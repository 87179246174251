import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../Box';
import { Button, type ButtonT } from '../Button';
export interface GroupActionT {
  actions?: Array<ButtonT & { key: string }> | undefined;
}

const ACTION_PATTERN = Object.freeze({
  Z: 'z',
  F: 'f',
  C: 'c',
});

export type ActionPatternT =
  (typeof ACTION_PATTERN)[keyof typeof ACTION_PATTERN];

const ACTION_PATTERN_MAP: {
  [key in ActionPatternT]: {
    flexDirection: string;
    justifyContent: string;
    mr: string;
    ml: string;
  };
} = {
  z: {
    flexDirection: 'flex-row-reverse',
    justifyContent: 'justify-start',
    mr: 'mr-2',
    ml: 'ml-0',
  },
  f: {
    flexDirection: 'flex-row',
    justifyContent: 'justify-start',
    mr: 'mr-0',
    ml: 'ml-2',
  },
  c: {
    flexDirection: 'flex-row-reverse',
    justifyContent: 'justify-center',
    mr: 'mr-2',
    ml: 'ml-0',
  },
};

export interface GroupT extends BoxT, GroupActionT {
  size?: ButtonT['size'];
  autoFocus?: boolean | undefined;
  actionPattern?: ActionPatternT;
}

export const Group = ({
  autoFocus,
  actions,
  size = 'compact',
  actionPattern = ACTION_PATTERN.F,
  className,
  ...props
}: GroupT) => {
  return (
    actions &&
    actions.length > 0 && (
      <Box
        {...props}
        className={clsx(
          'w-full',
          ACTION_PATTERN_MAP[actionPattern].flexDirection,
          ACTION_PATTERN_MAP[actionPattern].justifyContent,
          className,
        )}
      >
        {actions.map(({ key, className, ...props }, i) =>
          i === 0 ? (
            <Button
              key={key}
              className={className}
              variant="primary"
              {...props}
              autoFocus={autoFocus}
              size={size}
            />
          ) : (
            <Button
              key={key}
              {...props}
              variant="secondary"
              className={clsx(
                ACTION_PATTERN_MAP[actionPattern].ml,
                ACTION_PATTERN_MAP[actionPattern].mr,
                className,
              )}
              size={size}
            />
          ),
        )}
      </Box>
    )
  );
};
