import * as React from 'react';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { type Colors } from '@mentimeter/ragnar-colors';
import { designSystemConfig, type Fonts } from '@mentimeter/ragnar-dsc';
import { themes } from '@mentimeter/ragnar-colors';

export interface ThemeT {
  theme: { colors: Colors; fonts?: Fonts };
  themeClass?: string | undefined;
}

export function Theme({
  theme,
  themeClass,
  children,
}: React.PropsWithChildren<ThemeT>) {
  return (
    <ThemeProvider theme={theme} themeClass={themeClass}>
      {children}
    </ThemeProvider>
  );
}

export function Light({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.light} themeClass="light">
      {children}
    </Theme>
  );
}

export function Dark({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.dark} themeClass="dark">
      {children}
    </Theme>
  );
}

export function Primary({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.primary} themeClass="primary">
      {children}
    </Theme>
  );
}

export function Negative({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.negative} themeClass="negative">
      {children}
    </Theme>
  );
}

export function Notice({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.notice} themeClass="notice">
      {children}
    </Theme>
  );
}

export function Positive({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.positive} themeClass="positive">
      {children}
    </Theme>
  );
}

export function Info({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.info} themeClass="info">
      {children}
    </Theme>
  );
}

export function Neutral({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.neutral} themeClass="neutral">
      {children}
    </Theme>
  );
}

export function ThemeMentiLight({ children }: { children: React.ReactNode }) {
  return (
    <Theme
      theme={{
        colors: themes.light.colors,
        fonts: designSystemConfig.fonts,
      }}
      themeClass="light"
    >
      {children}
    </Theme>
  );
}
