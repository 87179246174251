import type { JSX } from 'react';
import {
  Light,
  Dark,
  Primary,
  Negative,
  Notice,
  Positive,
  Info,
  Neutral,
} from './Theme';

export type ThemeMapTypeT =
  | 'light'
  | 'dark'
  | 'primary'
  | 'negative'
  | 'notice'
  | 'positive'
  | 'info'
  | 'neutral';

export type ThemeMapT = {
  [key in ThemeMapTypeT]: ({
    children,
  }: {
    children: React.ReactNode;
  }) => JSX.Element;
};

export const themeMap: ThemeMapT = {
  light: Light,
  dark: Dark,
  primary: Primary,
  negative: Negative,
  notice: Notice,
  positive: Positive,
  info: Info,
  neutral: Neutral,
};
