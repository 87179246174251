import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Clickable, type ClickableT } from '../Clickable';

export interface DismissT extends Pick<ClickableT, 'p'> {
  dismissLabel?: string | undefined;
  onDismiss?: undefined | (() => void);
  className?: string | undefined;
}

export const Dismiss = ({
  onDismiss,
  dismissLabel = '',
  p,
  className,
}: DismissT) => {
  if (!(onDismiss && dismissLabel)) {
    return null;
  }

  return (
    <Clickable
      p={p}
      aria-label={dismissLabel}
      onClick={onDismiss}
      type="button"
      className={clsx('p-4 z-[1] text-current', className)}
    >
      {dismissLabel === '' ? (
        <CrossIcon size={3} />
      ) : (
        <CrossIcon aria-hidden={true} size={3} />
      )}
    </Clickable>
  );
};
