type TranslationObject = Record<string, string>;
type TranslationKey =
  | 'buttons'
  | 'messages'
  | 'question'
  | 'wait_active'
  | 'wait_open'
  | 'many_votes'
  | 'open'
  | 'prioritisation'
  | 'ranking'
  | 'quiz'
  | 'quiz_open'
  | 'state'
  | 'word_cloud'
  | 'qfa'
  | 'cfa'
  | 'success'
  | 'texts';

export type Translation = Record<TranslationKey, TranslationObject>;

export function requestLanguageFile(language: string): Promise<Translation> {
  let translationsImportPromise;

  switch (language) {
    case 'br': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-br" */
        './data/br.json'
      );
      break;
    }
    case 'ca': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ca" */ './data/ca.json'
      );
      break;
    }
    case 'cs': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-cs" */
        './data/cs.json'
      );
      break;
    }
    case 'cy': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-cy" */
        './data/cy.json'
      );
      break;
    }
    case 'da': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-da" */
        './data/da.json'
      );
      break;
    }
    case 'de': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-de" */
        './data/de.json'
      );
      break;
    }
    case 'es': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-es" */
        './data/es.json'
      );
      break;
    }
    case 'et': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-et" */
        './data/et.json'
      );
      break;
    }
    case 'fi': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-fi" */
        './data/fi.json'
      );
      break;
    }
    case 'fr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-fr" */
        './data/fr.json'
      );
      break;
    }
    case 'hi': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hi" */
        './data/hi.json'
      );
      break;
    }
    case 'hr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hr" */
        './data/hr.json'
      );
      break;
    }
    case 'hu': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-hu" */
        './data/hu.json'
      );
      break;
    }
    case 'id': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-id" */
        './data/id.json'
      );
      break;
    }
    case 'is': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-is" */
        './data/is.json'
      );
      break;
    }
    case 'it': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-it" */
        './data/it.json'
      );
      break;
    }
    case 'ja': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ja" */
        './data/ja.json'
      );
      break;
    }
    case 'lt': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-lt" */
        './data/lt.json'
      );
      break;
    }
    case 'nl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-nl" */
        './data/nl.json'
      );
      break;
    }
    case 'no': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-no" */
        './data/no.json'
      );
      break;
    }
    case 'pa': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pa" */
        './data/pa.json'
      );
      break;
    }
    case 'pl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pl" */
        './data/pl.json'
      );
      break;
    }
    case 'pt': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-pt" */
        './data/pt.json'
      );
      break;
    }
    case 'ro': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ro" */
        './data/ro.json'
      );
      break;
    }
    case 'ru': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ru" */
        './data/ru.json'
      );
      break;
    }
    case 'sl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-sl" */
        './data/sl.json'
      );
      break;
    }
    case 'srl': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-srl" */
        './data/srl.json'
      );
      break;
    }
    case 'sv': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-sv" */
        './data/sv.json'
      );
      break;
    }
    case 'ta': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-ta" */ './data/ta.json'
      );
      break;
    }
    case 'tr': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-tr" */
        './data/tr.json'
      );
      break;
    }
    case 'uk': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-uk" */
        './data/uk.json'
      );
      break;
    }
    case 'zh': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-zh" */
        './data/zh.json'
      );
      break;
    }
    case 'zhs': {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-zhs" */
        './data/zhs.json'
      );
      break;
    }
    default: {
      translationsImportPromise = import(
        /* webpackChunkName: "lang-defaul" */
        './data/en.json'
      );
      break;
    }
  }

  // @ts-expect-error-auto TS(2322) FIXME: Type 'Promise<Translation | { buttons: { refresh: ... Remove this comment to see the full error message
  return translationsImportPromise.then((file) => file.default);
}
