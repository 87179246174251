import * as React from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { inputVariants, type InputVariantProps } from '../utils';

export interface TextInputItemT
  extends React.ComponentProps<typeof FelaTextInputItem> {
  maxLength?: number | undefined;
  inputSize?: InputVariantProps['size'];
  status?: InputVariantProps['variant'];
}

const FelaTextInputItem = styled('input')('kosmosSpacing');

export const TextInputItem = React.forwardRef<HTMLInputElement, TextInputItemT>(
  (props, ref) => {
    const { className, ...rest } = props;

    const classes = inputVariants({
      size: rest.inputSize,
      variant: rest?.status,
    });

    return (
      <FelaTextInputItem
        ref={ref}
        type="text"
        className={cn(classes, 'r-textinput', className)}
        {...rest}
      />
    );
  },
);
