import type { StyleSet } from './style-properties';

export interface MarginST<S> {
  m?: S | undefined;
  mx?: S | undefined;
  my?: S | undefined;
  mt?: S | undefined;
  mr?: S | undefined;
  mb?: S | undefined;
  ml?: S | undefined;
}

export interface PaddingST<S> {
  p?: S;
  px?: S;
  py?: S;
  pt?: S;
  pr?: S;
  pb?: S;
  pl?: S;
}

export interface CommonST<C, S> extends MarginST<S>, PaddingST<S> {
  color?: C;
  bg?: C;
}

// type created during migration where we have to support both the old and new
type StyleSetWithoutKosmos = Exclude<
  StyleSet,
  | 'kosmosSpacing'
  | 'kosmosGrid'
  | 'kosmosBorderRadius'
  | 'kosmosFontSize'
  | 'kosmosLineHeight'
>;

export const COMMON: StyleSetWithoutKosmos[] = ['space', 'color'];

export interface LayoutST<D, H, W, O> {
  display?: D;
  height?: H;
  minHeight?: H;
  maxHeight?: H;
  width?: W;
  minWidth?: W;
  maxWidth?: W;
  overflow?: O;
}

export const LAYOUT: StyleSetWithoutKosmos[] = [
  'display',
  'height',
  'minHeight',
  'maxHeight',
  'width',
  'minWidth',
  'maxWidth',
  'overflow',
];

export interface TypographyST<FS, LS, LH, TA> {
  fontSize?: FS;
  letterSpacing?: LS;
  lineHeight?: LH;
  textAlign?: TA;
}

export const TYPOGRAPHY: StyleSetWithoutKosmos[] = [
  'fontSize',
  'letterSpacing',
  'lineHeight',
  'textAlign',
];

export interface FlexContainerST<F, FD, FW, AC, AI, JC, JI> {
  flex?: F;
  flexDirection?: FD;
  flexWrap?: FW;
  alignContent?: AC;
  alignItems?: AI;
  justifyContent?: JC;
  justifyItems?: JI;
}

export const FLEX_CONTAINER: StyleSetWithoutKosmos[] = [
  'flex',
  'flexDirection',
  'flexWrap',
  'alignContent',
  'alignItems',
  'justifyContent',
  'justifyItems',
];

export interface PositionST<P, Z, N> {
  position?: P | undefined;
  zIndex?: Z | undefined;
  top?: N | undefined;
  right?: N | undefined;
  bottom?: N | undefined;
  left?: N | undefined;
}

export const POSITION: StyleSetWithoutKosmos[] = ['position'];
