import get from 'lodash/get';

export type TranslateFnT = (
  key: string,
  data?: {
    [key: string]: string | number;
  } | null,
) => string;

export const createTranslationParser: (locale: {
  [key: string]: any;
}) => TranslateFnT = (locale) => (key, data) => {
  let translatedString: string = get(locale, key);

  if (data) {
    Object.keys(data).forEach((k) => {
      // If data === { nAnts: 5, nElephants 4 }
      // From: "${ nAnts } ants is more than ${ nElephants } elephants"
      // To:   "5 ants is more than 4 elephants"
      const regex = new RegExp('\\${\\s*' + k + '\\s*}', 'gmi');
      translatedString = translatedString.replace(regex, data[k]!.toString());
    });
  }

  return translatedString;
};
