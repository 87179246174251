'use client';
import * as React from 'react';
import en from '../../languages/data/en.json';
import type { Translation } from '../../languages';
import { requestLanguageFile } from '../../languages';
import type { TranslateFnT } from './translationParser';
import { createTranslationParser } from './translationParser';

export const TranslationContext: React.Context<TranslateFnT> =
  React.createContext(createTranslationParser(en));
TranslationContext.displayName = 'TranslationContext';

export const TranslationsProvider = ({
  locale,
  children,
  serverSideTranslation,
}: {
  serverSideTranslation?: Translation | undefined;
  locale: string;
  children: React.ReactNode;
}) => {
  const [currentTranslations, setCurrentTranslations] = React.useState(
    serverSideTranslation || en,
  );

  React.useEffect(() => {
    requestLanguageFile(locale).then(setCurrentTranslations);
  }, [locale]);

  const value = React.useMemo(
    () => createTranslationParser(currentTranslations),
    [currentTranslations],
  );

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslate: () => TranslateFnT = () =>
  React.useContext(TranslationContext);

export function Translate({ id }: { id: string }) {
  const translate = useTranslate();
  return translate(id);
}
