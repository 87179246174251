import { voting } from '@mentimeter/http-clients';
import type {
  ReactionPayload,
  ReactionPublishPayload,
  UpvotePayload,
} from '@mentimeter/http-clients';
import { getRegionByVoteKey } from '@mentimeter/region';

export const getSeriesById = (id: string) => {
  if (!id || !id.replace) {
    return Promise.reject(new Error('Invalid id given'));
  }
  const voteId = id.replace(/[^\d]/g, '');
  return voting().series.getById(voteId);
};

export function getSeriesByKey(key: string) {
  const region = getRegionByVoteKey(key);

  return voting({ region }).series.getByKey(key);
}

export function getSeriesIdByKey(key: string) {
  const region = getRegionByVoteKey(key);

  return voting({ region }).series.getSeriesIdByKey(key);
}

export function postVote(questionPublicKey: string, payload: any) {
  return voting().vote.post(questionPublicKey, payload);
}

export function postUpvote({
  questionPublicKey,
  payload,
  voteId,
}: {
  questionPublicKey: string;
  voteId: string;
  payload: UpvotePayload;
}) {
  return voting().vote.upvote(questionPublicKey, voteId, payload);
}

export function publishReaction(
  questionPublicKey: string,
  payload: ReactionPublishPayload,
) {
  return voting().audience.reactions.publish(questionPublicKey, payload);
}

export function postReaction(
  questionPublicKey: string,
  payload: ReactionPayload,
) {
  return voting().audience.reactions.post(questionPublicKey, payload);
}

export const fetchGroupedResults = async (questionPublicKey: string) => {
  return voting().groups.get(questionPublicKey);
};
